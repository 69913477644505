export enum UserSetting {
  Profile = 'Profile',
  SignOut = 'Sign out'
}

export enum RoutePath {
  SignIn = 'signin', //'signin',
  FaildSignIn = 'login-failed',
  Search = 'search',
  Dashboard = 'dashboard',
  UserProfile = 'user-profile',
  Bookmarks = 'bookmarks',
  MyStuff = 'my-stuff',
  MyPosts = 'my-posts',
  AddPost = 'add-posts',
  Registration = 'registration',
  AIAssist = 'ai-assist',
  BDAssist = 'bd-assist',
  Feed = 'feed',
  Home = 'feed',
  Root = ''
}

export enum InterestType {
  CGIFBU = 'CGIFBU',
  GA = 'GA',
  GEN = 'GEN',
  DEFAULT = 'DEFAULT'
}

export enum NavItemLabels {
  Feed = 'Feed',
  Search = 'Search',
  MyStuff = 'My Stuff',
  AddPost = 'Add Post',
  MyPosts = 'My Posts',
  AIAssist = 'AI Assist',
  BDAssist = 'BD Assist'
}

/**
 * The type of search result item.
 */
export enum ResultType {
  Search,
  Post
}

export enum DataCardType {
  Post = 'post',
  Govwin = 'govwin',
  Snf = 'sharepoint'
}

export enum DataCardLabel {
  Post = 'Post',
  Govwin = 'GovWin',
  Snf = 'CGIF Document Repository'
}
